<!-- 创新素质指数报告 mobile -->
<template>
  <div class="main">
    <div class="print-page" id="cover" name="cover">
      <h1 class="lj-text-center">创新素质指数报告</h1>
      <p>您的创新素质指数为：{{ cxcy }}</p>
      <div
        id="cxcyContainer"
        style="height: 320px; margin-left: auto; margin-right: auto"
      ></div>
      <h2>对创新素质指数分数的说明</h2>
      <p>
        30分以下（包括30分）：创新能力比较弱。如果您的测评分数在这个区间，说明您目前的创新能力比较弱。您在创新意识、创新思维、创新知识，与创新人格这四个方面均有较大的提升空间。
      </p>
      <p>
        建议您提升对事物的好奇心，增强对问题解决的兴趣与欲望。训练自己发现并提出问题的能力。同时，请不要在面对困难时立刻“知难而退”。在事情暂时得不到解决的时候不要灰心，再试试看。另外，要有意识地训练自己的创新思维。注意训练自己观察问题的能力，并且尝试通过自己独立的思考想出办法解决问题。例如尝试使用逆向思维、类比思维和组合思维找到一个新点子。您属于喜欢安全的个性，因此会本能的回避所有的冒险。如果想提升自己的创新能力，可以尝试跳出自己的“舒适区”，尝试一些平常不大敢做的小事，比如您可以试试在一大群人面前做一次演讲，或是看一场自己以外不大感兴趣的电影。这些小事都可以帮助您增强创新的勇气。另外，知识对创新同样重要，请不要忘记找一个自己感兴趣的方向并全身心地投入去学习，去吸收知识，要知道只有勇气是不可能解决问题的。最后，也不要因为分数不理想而太过紧张，相信自己通过训练能够提升自己的创新素质。
      </p>
      <p>
        30-60分（包括60分）：正常区间。如果您正巧在这个分数区间，您处在一个多数人所在的位置。这意味着您的创新素质不错，但仍然有提升的空间。您具有一定的创新意识，也就是说您对事物具有一定的好奇心，您可能对自己感性去的领域会用点心，但是在与自己无感的领域则会完全无感，也不想了解。如果您希望提升自己的创新精神，那么请尝试突破自己的“无感区”，去了解一下自己平时不那么感兴趣的领域，比如如果您是女孩，了解一下汽车引擎是如何工作的；如果您是男孩，可以去看看与发型相关的书籍。另外，请加强对问题的创新思维。这包括提升自己的批判性思维，也就是对那些“理所当然”的事进行质疑。同样，知识对创新的作用不容忽视，如果要提升自己的知识创新，需要更努力的吸收知识为你所用，这有助于您在碰到问题时想出办法。最后，请不要灰心，您已经不错了，只要稍加注意和有意识的练习，提升只是时间的问题。
      </p>
      <p>
        60-75分（包括75分）：比较高的创新素质。非常好，如果您处在这个区间，意味着您已经具备了比较优秀的创新精神和创新能力。也就是说，您的创新能力高于大多数人。您不愿意不安于现状，对自己的未知领域有比较强烈的探索精神。您不愿意跟随，大多数时候，您是一位特立独行的人。凡事您总是希望通过自己的思考去找到答案。您也非常愿意为了解决问题花时间学习，您希望通过对知识的系统掌握去搞定一个难题。大家往往会对您说：“伙计，挺棒的。”
      </p>
      <p>
        但是，请注意您仍然有提升的空间，这需要您更富有开拓精神，在所有人都说不的时候能够坚持自己的想法。这需要勇气，有时候甚至需要作出一些牺牲。要成为顶尖的创新人才，有时候否定自己也是在所难免的。
      </p>
      <p>
        75分以上：恭喜您，您具有非常强的创新精神，同时您的创新能力也是令人夸赞的。相信您在生活中是一个充满好奇心，具有强烈的“问题意识”的人。您推崇创新，追求创新，以创新为荣。在遇到问题的时候，总是想法设法地去解决。即使一时解决不了问题，您也不会因此放弃，您总是耐心地观察问题，并且会多次尝试不同的方法解决问题，知道问题找到答案为止。您的创新思维能力很强，您具有灵活的思维能力，能够举一反三地解决问题。为了更好的解决问题，您会不断地在您所在的专业吸收知识，并且整理加工，直到自己完全消化吸收。这意味着您能在碰到问题时有更多的办法。您具有敢闯、敢冒风险、敢于怀疑和批判的科学精神，具有良好的精神状态和心理素质。
      </p>
      <p>当然，我们也要提醒您，凡事悠着点。速度不是最重要的，方向才是。</p>
      <p class="page-footer no-indent">
        本产品由灯塔教育专利智能专业匹配算法驱动（专利号：201810642977.1）
      </p>
    </div>
    <div class="print-page">
      <h2>相关知识</h2>
      <p>
        创新素质(Innovative
        quality)包括创新意识、创新思维、创新知识，与创新人格。
      </p>
      <p>
        创新意识是一种不安于现状、精益求精的意识，面对任何未知的问题、未知的领域有勇于尝试的冲动，是不断探索、勤于思考，善于发现并提出问题，求新、求异的兴趣和欲望；创新意识是创新的重要心理素质之一，是创新思维与创新能力的前提，只有在强烈的创新意识引导下，才能产生强烈的创新动机，并充分发挥其创造的潜能。
      </p>
      <p>
        创新思维是由相辅相成的发散思维与聚合思维所组成，是发明或发现一种新方式用以处理某种事物的思维过程。其明显特征包括积极的求异性，敏锐的观察力，创造性的想象，独特的知识结构以及活跃的灵感。创新思维是整个创新活动智力结构的关键，是创新能力的核心，它可以使学生顺利解决新的问题，牢固地掌握新知识，并能将这些知识广泛地迁移到学习新知识的过程中，使学习活动顺利完成。属于非智力因素，是创新活动的内在动力机构。
      </p>
      <p>
        创新知识属于创新活动的工作机构。具备宽广而扎实的基础知识，广阔的视野，以及善于综合开拓新领域的能力，掌握创新知识的方法论，尤其是具备良好的创造技能，包括一般工作能力和动手能力，熟练掌握和运用创造技法的能力，创新成果的表达能力（论文写作）、表现能力（艺术创作）和物化能力（创造设想物化为模型和产品）
      </p>
      <p>
        创新人格指具有好奇心、求知欲，具有献身科学、献身人类事业的内在动力和坚强意志，具有敢闯、敢冒风险、敢于怀疑和批判的科学精神，具有良好的精神状态和心理素质。
      </p>
      <p>
        我们的创新素质指数(Innovative quality
        index)从您的内在特质中提炼出包括创新意识、创新思维、创新知识和创新人格相关的参数，并通过我们的智能算法计算，最终生成您的创新素质指数。这个指数是您目前所具备创新能力的重要参考指标。如果你的创新素质指数没有达到您的预期，也不必过分担心。因为一个人的创新能力并非一成不变的，它是动态的，是可以通过有意识的自我训练提高的。如果您在平常注意训练自己，是可以达到一个自己满意的状态的。
      </p>
      <div class="button-wrapper">
        <a
          class="lj-btn lj-btn-secondary"
          @click="jumpPage('reportCenter')"
          style="margin-right: 1rem"
          >返回测评导航</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import './../../../../Public/utils/chartjs/Chart'
import './../../../../Public/utils/highcharts/highcharts'
import 'vue-chartjs'
import 'chart.js'
import { queryCxcy } from './../../../../api/major'
export default {
  data: function () {
    return {
      cxcy: 80
    }
  },
  computed: {
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  mounted: function () {
    if (this.isMobile === null) {
      this.$router.push({
        path: 'HomeMajorStdResultPage3'
      })
      return
    }
    queryCxcy({}).then(res => {
      if (res.rescode == 200) {
        this.cxcy = res.data.inovation_index
        var option
        option = {
          tooltip: { formatter: '{a} <br/>{b} : {c}%' },
          toolbox: {
            feature: { restore: {}, saveAsImage: {} }
          },
          series: [
            {
              name: '创新素质指数',
              type: 'gauge',
              detail: { formatter: '{value}' },
              data: [{ value: this.cxcy, name: '您的创新素质指数' }]
            }
          ]
        }
        option && leidaGraph.setOption(option)
      }
    })
    var leidaGraph = this.$echarts.init(
      document.getElementById('cxcyContainer')
    )
    var option
    option = {
      tooltip: { formatter: '{a} <br/>{b} : {c}%' },
      toolbox: {
        feature: { restore: {}, saveAsImage: {} }
      },
      series: [
        {
          name: '创新素质指数',
          type: 'gauge',
          detail: { formatter: '{value}' },
          data: [{ value: this.cxcy, name: '您的创新素质指数' }]
        }
      ]
    }
    option && leidaGraph.setOption(option)
  },
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    //生成从minNum到maxNum的随机数
    randomNum (minNum, maxNum) {
      switch (arguments.length) {
        case 1:
          return parseInt(Math.random() * minNum + 1, 10)
          break
        case 2:
          return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
          break
        default:
          return 0
          break
      }
    },

    print: function () {
      this.$router.push({
        path: 'HomeMajorStdPrint'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.mbti-describe {
  text-align: left;
}
@import url('./../../../../Public/resources/css/templates/home/major/std_mobile_result.min.css');
</style>
